
import { Component, Vue, Emit, Watch, Prop } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";

let logo = require("./../../assets/imgs/logo.png");

@Component({
  name: "CommonSelect",
  computed: {
    ...mapGetters("course", ["delSelectAttr", "markerList", "subjectLevel", 'unSelectFlagStr']),
  },
  methods: {
    ...mapMutations("course", ["setDelSelectAttr"]),
  },
})
export default class CommonSelect extends Vue {
  delSelectAttr!: string;
  setDelSelectAttr!: any;
  subjectLevel!: string;
  markerList!: Array<any>;
  unSelectFlagStr!: string
  @Prop() commonSelectData!: any;
  @Prop() parent!: string;

  private levels: Array<any> = []; // 班型
  private areas: Array<any> = []; // 校区
  private teachers: Array<any> = []; // 老师
  private items: Array<any> = []; // 具体课程数据
  private attrArr: Array<string> = ["levels", "areas", "teachers"];
  private selectTableItem: any = {};
  // 选择的内容
  private selectObj: any = {
    levels: "",
    areas: "",
    teachers: "",
    restnum: "",
  };
  private headers: Array<string> = []; // 表格头
  private periods: Array<any> = []; // 日期数组
  private tableDataList: Array<any> = []; // 表格数据

  private mouseoverFlag: string = "";
  private map: any = {};

  private curSubjectLevel: string = "";

  private unSelectFlagObj: any = {
    levels: false,
    areas: false,
    teachers: false,
    restnum: false
  }
  // 不改变之前的逻辑，翻译date
  private DateObj = {
    "周一": "Mon",
    "周二": "Tues",
    "周三": "Wed",
    "周四": "Thur",
    "周五": "Fri",
    "周六": "Sat",
    "周日": "Sun"
  }

  @Watch("delSelectAttr", { immediate: true, deep: true })
  delSelectAttrWatch(newVal: any): void {
    if (newVal && newVal != "restnum") {
      this.setSelectItem({ text: this.selectObj[newVal] }, newVal);
    } else {
      this.setSelectRestnum({ text: this.selectObj[newVal], showText: this.selectObj[newVal], period: 'dsa' });
    }
  }
  @Watch('unSelectFlagStr', { immediate: true, deep: true })
  unSelectFlagStrWatch(newVal: string): void {
    Object.keys(this.unSelectFlagObj).forEach((attr: string): void => {
      this.unSelectFlagObj[attr] = false
      if(attr == newVal) {
        this.unSelectFlagObj[attr] = true
      }
    })
  }

  @Watch("commonSelectData", { immediate: true, deep: true })
  commonSelectDataWatch(newVal: any): void {
    this.resetAllData();
    if (newVal && newVal.items && Array.isArray(newVal.items) && newVal.items.length > 0) {
      this.initEmptyData(newVal.levels, "levels");
      this.initEmptyData(newVal.areas, "areas");
      this.initEmptyData(newVal.teachers, "teachers");
      this.items = newVal.items;
      this.headers = newVal.headers; // 初始化表头
      this.initPeriod(newVal.periods);
      this.initRestnum();
      console.log(this.tableDataList, this.headers, '返回表格数据')
    }

  }
  @Watch("selectObj", { immediate: true, deep: true })
  selectObjWatch(newVal: any): void {
    if (newVal.levels && newVal.areas && newVal.teachers && newVal.restnum) {
      let _temp: any = {}
      this.items.forEach((item: any, index: number): void => {
        let _sLevels: string = newVal.levels,
          _sAreas: string = newVal.areas,
          _sTeachers: string = newVal.teachers,
          _sRestnum: string = newVal.restnum,
          _aLevels: string = item.classlevel,
          _aAreas: string = item.schoolarea,
          _aTeachers: string = item.teachername,
          _aRestnum: string = `${item.header}${item.week}${item.during}${item.period}`;
        if (_sLevels == _aLevels && _sAreas == _aAreas && _sTeachers == _aTeachers && _sRestnum == _aRestnum && !_temp.id) {
          _temp = { ...item }
        }
      });
      if(_temp.id) {
        this.commonselectCb(_temp)
      }
    } else {
      this.commonselectCb({})
    }
  }
  @Watch("subjectLevel", { immediate: true, deep: true })
  subjectLevelWatch(newVal: string): void {
    if (newVal) {
      this.curSubjectLevel = newVal.replace("暂无", "None");
    }
  }
  @Emit()
  private commonselectCb(item: any): any {
    return item;
  }
  @Emit()
  private commonselectLat(item: any): any {
    return item;
  }
  private resetAllData(): void {
    this.levels = []; // 班型
    this.areas = []; // 校区
    this.teachers = []; // 老师
    this.items = []; // 具体课程数据
    this.selectTableItem = {};
    // 选择的内容
    this.selectObj = {
      levels: "",
      areas: "",
      teachers: "",
      restnum: "",
    };
    this.headers = []; // 表格头
    this.periods = []; // 日期数组
    this.tableDataList = []; // 表格数据

    this.mouseoverFlag = "";
    this.map = {};
  }
  // 初始化text，但是没有设置levels、areas、teachers
  private initEmptyData(arr: Array<any>, attr: string): void {
    let _this: any = this;
    if (Array.isArray(arr) && arr.length > 0) {
      arr.forEach((item: string, index: number): void => {
        let _temp: any = {
          text: item,
          status: 1,
          levels: [],
          areas: [],
          teachers: [],
          hasenter: 0,
          stdnum: 0,
          full: false,
          doubleAttr: ''
        };
        _this.$set(_this[attr], index, _temp);
      });
    }
  }
  // 初始化日期、时间
  private initPeriod(periods: Array<string>): void {
    this.periods = [];
    periods.forEach((item: string, index: number): void => {
      let _arr: Array<string> = item.split("JL"),
        _item: string = `${_arr[1].split("-")[0]})`,
        _subArr: Array<string> = _arr[1].split("("),
        _date: string = _subArr[0],
        _time: string = _subArr[1].replace(")", ""),
        _temp: any = {
          showPeriod: _item,
          showDate: _date,
          showTime: _time,
          period: item,
        };
      this.periods.push(_temp);
    });
  }

  // 初始化表格数据
  private initRestnum(): void {
    let _this: any = this;
    this.attrArr.forEach((attr: string, idx: number): void => {
      // 重置数字
      this.resetFullNum(attr);
    });

    // 表格数据 占位
    this.periods.forEach((item: any, index: number): void => {
      let oArr: Array<any> = [{ text: item.showDate }, { text: item.showTime }];
      if (this.parent == "1") {
        oArr = [{ text: item.showDate }, { text: item.showTime }];
      } else {
        const during = item.showDate === '下午'?'P.M.':item.showDate === '上午'?'A.M.':'P.M.'
        oArr = [{ text: `${during}(${item.showTime.split("-")[0]})` }];
      }
      this.headers.forEach((str: string, idx: number): void => {
        let _item: any = {
          showPeriod: item.showPeriod,
          period: item.period,
          showDate: item.showDate,
          showTime: item.showTime,
          header: str,
          mineLevels: "",
          mineAreas: "",
          mineTeachers: "",
          minehasenter: 0,
          minestdnum: 0,
          text: "--",
          status: 3,
          showText: "",
          week: "",
          during: "",
        };
        if (this.parent == "1") {
          _item.text = "";
          _item.status = 1;
        }
        oArr.push(_item);
      });
      _this.$set(this.tableDataList, index, oArr);
    });

    this.items.forEach((item: any, index: number): void => {
      let _indexArr: Array<number> = [
        this.getIndex(item.classlevel, this.levels, "text"), // 班型
        this.getIndex(item.schoolarea, this.areas, "text"), // 校区
        this.getIndex(item.teachername, this.teachers, "text"), // 老师
      ];
      // 初始化levels、areas、teachers的hasenter、stdnum，用于标识levels、areas、teachers是否满班
      this.attrArr.forEach((attr: string, idx: number): void => {
        // 设置数字
        this.setFullNum(attr, _indexArr[idx], item);
      });
      this.initSubArr();
      // 设置表格数据
      this.setTableData(item);
    });

    this.attrArr.forEach((attr: string, index: number): void => {
      _this[attr].forEach((item: any, idx: number): void => {
        if (item.hasenter == item.stdnum && item.stdnum != 0) {
          _this.$set(_this[attr][idx], "full", true);
        } else {
          _this.$set(_this[attr][idx], "full", false);
        }
      });
    });
  }
  // 重置levels、areas、teachers的hasenter、stdnum
  private resetFullNum(attr: string): void {
    let _this: any = this;
    _this[attr].forEach((itm: any, idx: number): void => {
      _this[attr][idx].hasenter = 0;
      _this[attr][idx].stdnum = 0;
      _this.$set(_this[attr][idx], "full", false);
    });
  }
  /**
   * 获取元素在数组中的索引
   * @params data string, object 需要查询的对象
   * @params arr Array 目标数组
   * @params attr 根据属性判断
   * @return number 查询的索引
   */
  private getIndex(data: string, arr: Array<any>, attr?: string): number {
    let _index: number = -1;
    if (attr) {
      arr.forEach((item: any, index: number): void => {
        if (item[attr] == data) {
          _index = index;
        }
      });
    } else {
      arr.forEach((item: any, index: number): void => {
        if (item == data) {
          _index = index;
        }
      });
    }
    return _index;
  }

  // 初始化levels、areas、teachers
  private initSubArr(): void {
    this.items.forEach((item: any, index: number): void => {
      this.setSubArr("levels", item.classlevel, [
        `areas***${item.schoolarea}`,
        `teachers***${item.teachername}`,
      ], `${item.schoolarea}_${item.teachername}`);
      this.setSubArr("areas", item.schoolarea, [
        `levels***${item.classlevel}`,
        `teachers***${item.teachername}`,
      ], `${item.teachername}_${item.classlevel}`);
      this.setSubArr("teachers", item.teachername, [
        `areas***${item.schoolarea}`,
        `levels***${item.classlevel}`,
      ], `${item.classlevel}_${item.schoolarea}`);
    });
  }
  private setSubArr(
    attr: string,
    attrData: string,
    setData: Array<string>,
    doubleAttr: string
  ): void {
    let _this: any = this,
      _index: number = this.getIndex(attrData, _this[attr], "text");
    setData.forEach((item: string, index: number): void => {
      // let _arr: Array<string> = item.split("***"),
      //   _temp: Array<string> = [..._this[attr][_index][_arr[0]]];
      // _temp.push(_arr[1]);
      // _temp = [...new Set(_temp)];
      // _this.$set(_this[attr][_index], _arr[0], _temp);
      let _arr: Array<string> = item.split("***"),
        _temp: any = _this[attr][_index],
        _tArr: Array<string> = _temp[_arr[0]],
        _dArr: Array<string> = _temp.doubleAttr.split('***');
      _tArr.push(_arr[1])
      _tArr = [ ...new Set(_tArr) ]

      _dArr.push(`${doubleAttr}`)
      _dArr = [ ...new Set(_dArr) ]
      _temp[_arr[0]] = _tArr
      _temp.doubleAttr = _dArr.join('***')
      _this.$set(_this[attr], [_index], _temp);
    });
  }

  // 初始化levels、areas、teachers的hasenter、stdnum
  private setFullNum(attr: string, index: number, item: any): void {
    let _this: any = this,
      _sLevels: string = this.selectObj.levels,
      _sAreas: string = this.selectObj.areas,
      _sTeachers: string = this.selectObj.teachers,
      _sRestnum: string = this.selectObj.restnum,
      _aLevels: string = item.classlevel,
      _aAreas: string = item.schoolarea,
      _aTeachers: string = item.teachername,
      _aRestnum: string = `${item.header}${item.week}${item.during}${item.period}`;
    _this[attr].forEach((itm: any, idx: number): void => {
      if ( idx == index && (
          (!_sLevels && !_sAreas && !_sTeachers && !_sRestnum) ||
          (_sLevels && _sLevels == _aLevels && !_sAreas && !_sTeachers && !_sRestnum) ||
          (!_sLevels && _sAreas && _sAreas == _aAreas && !_sTeachers && !_sRestnum) ||
          (!_sLevels && !_sAreas && _sTeachers && _sTeachers == _aTeachers && !_sRestnum) ||
          (!_sLevels && !_sAreas && !_sTeachers && _sRestnum && _sRestnum == _aRestnum) ||
          (_sLevels && _sAreas && !_sTeachers && _sLevels == _aLevels && _sAreas == _aAreas && !_sRestnum) ||
          (_sLevels && !_sAreas && _sTeachers && _sLevels == _aLevels && _sTeachers == _aTeachers && !_sRestnum) ||
          (!_sLevels && _sAreas && _sTeachers && _sAreas == _aAreas && _sTeachers == _aTeachers && !_sRestnum) ||
          (_sLevels && !_sAreas && !_sTeachers && _sLevels == _aLevels && _sRestnum && _sRestnum == _aRestnum) ||
          (!_sLevels && _sAreas && !_sTeachers && _sAreas == _aAreas && _sTeachers == _aTeachers && _sRestnum && _sRestnum == _aRestnum) ||
          (!_sLevels && !_sAreas && _sTeachers && _sTeachers == _aTeachers && _sRestnum && _sRestnum == _aRestnum) ||
          (_sLevels && _sAreas && _sTeachers && _sLevels == _aLevels && _sAreas == _aAreas && _sTeachers == _aTeachers && !_sRestnum) ||
          (!_sLevels && _sAreas && _sTeachers && _sAreas == _aAreas && _sTeachers == _aTeachers && _sRestnum && _sRestnum == _aRestnum) ||
          (_sLevels && !_sAreas && _sTeachers && _sLevels == _aLevels && _sTeachers == _aTeachers && _sRestnum && _sRestnum == _aRestnum) ||
          (_sLevels && _sAreas && !_sTeachers && _sLevels == _aLevels && _sAreas == _aAreas && _sRestnum && _sRestnum == _aRestnum) ||
          (_sLevels && _sAreas && _sTeachers && _sLevels == _aLevels && _sAreas == _aAreas && _sTeachers == _aTeachers && _sRestnum && _sRestnum == _aRestnum))
      ) {
        _this[attr][index].hasenter += item.hasenter;
        _this[attr][index].stdnum += item.stdnum;
      }
    });
  }
  // 设置表格数据的具体方法
  private setTableData(item: any): void {
    let _this: any = this,
      _pIndex: number = this.getIndex(item.period, this.periods, "period"), // 日期序号
      _hIndex: number = this.parent == "1" ? this.getIndex(item.header, this.headers) + 2 : this.getIndex(item.header, this.headers) + 1, // 时间序号
      _temp: any = { ...this.tableDataList[_pIndex][_hIndex] }; // 循环过程中相同_pIndex、_hIndex需要处理的表格元素
    if ( this.selectObj.levels && this.selectObj.areas && this.selectObj.teachers ) {
      // 条件全选
      if ( item.classlevel == this.selectObj.levels && item.schoolarea == this.selectObj.areas && item.teachername == this.selectObj.teachers ) {
        let _num: number = Number(item.hasenter / item.stdnum);
        _temp = { ..._temp, ...item };
        // _temp.text = item.stdnum > item.hasenter ? `剩余：${item.stdnum - item.hasenter}` : "Full";
        _temp.text = item.stdnum > item.hasenter ? `Popular` : "Full";
        _temp.status = _num < 0.6 ? 1 : 0.6 <= _num && _num < 1 ? 2 : 4;
        _temp.showText = `${item.header}${item.week}${item.during}${item.period}`;
        _this.$set(_this.tableDataList[_pIndex], _hIndex, _temp);
      }
    } else {
        if (
            (!this.selectObj.levels && !this.selectObj.areas && !this.selectObj.teachers) ||
            (this.selectObj.levels && !this.selectObj.areas && !this.selectObj.teachers && item.classlevel == this.selectObj.levels) ||
            (!this.selectObj.levels && this.selectObj.areas && !this.selectObj.teachers && item.schoolarea == this.selectObj.areas) ||
            (!this.selectObj.levels && !this.selectObj.areas && this.selectObj.teachers && item.teachername == this.selectObj.teachers) ||
            (this.selectObj.levels && this.selectObj.areas && !this.selectObj.teachers && item.classlevel == this.selectObj.levels && item.schoolarea == this.selectObj.areas) ||
            (this.selectObj.levels && !this.selectObj.areas && this.selectObj.teachers && item.classlevel == this.selectObj.levels && item.teachername == this.selectObj.teachers) ||
            (!this.selectObj.levels && this.selectObj.areas && this.selectObj.teachers && item.schoolarea == this.selectObj.areas && item.teachername == this.selectObj.teachers)
        ) {
            _temp.week = item.week;
            _temp.during = item.during;
            _temp.mineLevels += `***${item.classlevel}`;
            _temp.mineAreas += `***${item.schoolarea}`;
            _temp.mineTeachers += `***${item.teachername}`;
            _temp.minehasenter += item.hasenter;
            _temp.minestdnum += item.stdnum;
            let _num: number = _temp.minehasenter / _temp.minestdnum,
                _itemShowText: string = `${item.header}${item.week}${item.during}${item.period}`;
            if (this.parent == "1") {
                // _temp.text = _temp.minestdnum > _temp.minehasenter ? `剩余：${_temp.minestdnum - _temp.minehasenter}` : "Full";
              _temp.text = _temp.minestdnum > _temp.minehasenter ? `Popular` : "Full";
              _temp.status = _num < 0.6 ? 1 : 0.6 <= _num && _num < 1 ? 2 : 4;
                _temp.showText = _itemShowText;
            } else {
                if (!this.selectTableItem.showText) {
                    _temp.text = _num < 0.6 ? "Popular" : 0.6 <= _num && _num < 1 ? "Tight" : "Full";
                    _temp.status = _num < 0.6 ? 1 : 0.6 <= _num && _num < 1 ? 2 : 4;
                    _temp.showText = _itemShowText;
                } else {
                    let _selectShowText: string = `${this.selectTableItem.header}${this.selectTableItem.week}${this.selectTableItem.during}${this.selectTableItem.period}`;
                    if (_itemShowText != _selectShowText) {
                        _temp.status = 3;
                        _temp.text = "--";
                    } else {
                        _temp.text = _num < 0.6 ? "Popular" : 0.6 <= _num && _num < 1 ? "Tight" : "Full";
                        _temp.status = _num < 0.6 ? 1 : 0.6 <= _num && _num < 1 ? 2 : 4;
                        _temp.showText = _itemShowText;
                        _temp.status = 1;
                    }
                }
            }
            _this.$set(_this.tableDataList[_pIndex], _hIndex, _temp);
        }
    }
  }

  /**
   * 单击班型、校区、老师的操作
   *    1、修改其他出当前选项以外的所有项数据
   *    2、修改表格数据
   */
  private setSelectItem(item: any, attr: string): void {
      this.unSelectFlagObj[attr] = false
      // if ( item.status == 3 || item.status == 5 || (this.selectObj[attr] != "" && this.selectObj[attr] != item.text) || item.full) {
       if ( item.status == 3 || item.status == 5 || (this.selectObj[attr] != "" && this.selectObj[attr] != item.text)) {
      } else {
          if (this.selectObj[attr] == item.text) {
              this.selectObj[attr] = "";
          } else {
              this.selectObj[attr] = item.text;
          }
          if ( this.selectTableItem.showText && this.selectTableItem.mineLevels && this.selectTableItem.mineAreas && this.selectTableItem.mineTeachers ) {
              this.setStatusToFive("levels", this.selectTableItem.mineLevels);
              this.setStatusToFive("areas", this.selectTableItem.mineAreas);
              this.setStatusToFive("teachers", this.selectTableItem.mineTeachers);
          }
          this.commonselectLat(this.selectObj);
          this.setSubArrBySelect(attr);
          this.curSetDelSelectAttr();
      }
  }
  // 设置levels、areas、teachers的status，由from变to
  private resetStatus(attr: string, from: number, to: number): void {
    let _this: any = this;
    _this[attr].forEach((item: any, idx: number): void => {
      if (item.status == from && !item.full) {
        _this.$set(_this[attr][idx], "status", to);
      }
    });
  }
  // 单一条件，设置levels、areas、teachers的status为3
  private setStatusToThreeSimple(attr: string, term: string): void {
    let _this: any = this;
    _this[attr].forEach((item: any, idx: number): void => {
      // if (
      //   !item[term].includes(this.selectObj[term]) &&
      //   item.status != 5 && 
      //   !item.full
      // ) {
      //   _this.$set(_this[attr][idx], "status", 3);
      // }
      if (
        !item[term].includes(this.selectObj[term]) &&
        item.status != 5
      ) {
        _this.$set(_this[attr][idx], "status", 3);
      }
    });
  }
  // 双条件，设置levels、areas、teachers的status为3
  private setStatusToThreeDouble( attr: string, term1: string, term2: string): void {
    let _this: any = this;
    console.log(_this[attr])
    _this[attr].forEach((item: any, idx: number): void => {
      if (_this[attr][idx].status != 5 && _this[attr][idx].status != 3) {
        // if (
        //   item[term1].includes(${item.teachername}) &&
        //   item[term2].includes(this.selectObj[term2]) && 
        //   !item.full
        // ) {
        //   _this.$set(_this[attr][idx], "status", 1);
        // } else {
        //   _this.$set(_this[attr][idx], "status", 3);
        // }
        if (
         item.doubleAttr.indexOf(`${this.selectObj[term1]}_${this.selectObj[term2]}`) >= 0
        ) {
          _this.$set(_this[attr][idx], "status", 1);
        } else {
          _this.$set(_this[attr][idx], "status", 3);
        }
      }
    });
  }
  // 根据选中的表格元素，设置levels、areas、teachers的status为5
  private setStatusToFive(attr: string, temp: string): void {
    let _this: any = this;
    _this[attr].forEach((item: any, idx: number): void => {
      if (temp.indexOf(item.text) < 0) {
        _this.$set(_this[attr][idx], "status", 5);
      }
    });
  }
  /**
   * 根据选择的内容设置其他的属性
   * @params attr 当前属性，如levels，则需要设置的就是areas、teachers
   */
  private setSubArrBySelect(attr?: string): void {
    this.resetStatus("levels", 3, 1);
    this.resetStatus("areas", 3, 1);
    this.resetStatus("teachers", 3, 1);

    this.initRestnum();
    // 一个条件
    if ( this.selectObj.levels && !this.selectObj.areas && !this.selectObj.teachers ) {
      this.setStatusToThreeSimple("areas", "levels");
      this.setStatusToThreeSimple("teachers", "levels");
    }
    if ( !this.selectObj.levels && this.selectObj.areas && !this.selectObj.teachers ) {
      this.setStatusToThreeSimple("levels", "areas");
      this.setStatusToThreeSimple("teachers", "areas");
    }
    if ( !this.selectObj.levels && !this.selectObj.areas && this.selectObj.teachers ) {
      this.setStatusToThreeSimple("levels", "teachers");
      this.setStatusToThreeSimple("areas", "teachers");
    }
    // 两个条件
    if ( this.selectObj.levels && this.selectObj.areas && !this.selectObj.teachers ) {
      this.setStatusToThreeDouble("teachers", "levels", "areas");
    }
    if ( this.selectObj.levels && !this.selectObj.areas && this.selectObj.teachers ) {
      this.setStatusToThreeDouble("areas", "teachers", "levels");
    }
    if ( !this.selectObj.levels && this.selectObj.areas && this.selectObj.teachers ) {
      this.setStatusToThreeDouble("levels", "areas", "teachers");
    }

    if ( this.selectTableItem.showText && this.selectTableItem.mineLevels && this.selectTableItem.mineAreas && this.selectTableItem.mineTeachers && (this.selectObj.levels || this.selectObj.areas || this.selectObj.teachers)) {
      this.getOnlyTableItem();
    }

    if ( !this.selectObj.levels && !this.selectObj.areas && !this.selectObj.teachers && this.selectObj.restnum ) {
      this.tableDataList.forEach((outer: Array<any>, oIndex: number): void => {
        if (outer && Array.isArray(outer) && outer.length > 0) {
          outer.forEach((inner: any, iIndex: number): void => {
            if (inner.showText == this.selectObj.restnum) {
              this.selectTableItem = { ...inner };
            }
          });
        }
      });
    }

    if ( this.selectTableItem.showText && !this.selectTableItem.mineLevels && !this.selectTableItem.mineAreas && !this.selectTableItem.mineTeachers ) {
      this.setByOnlyTableItem(attr);
    }
    
  }
  // 根据表格选项和levels、area、teachers的部分条件判断是不是唯一选项
  private getOnlyTableItem(): void {
    let _tempArr: Array<any> = [],
      _selectShowText: string = `${this.selectTableItem.header}${this.selectTableItem.week}${this.selectTableItem.during}${this.selectTableItem.period}`;
    this.items.forEach((item: any, index: number): void => {
      let _itemShowText: string = `${item.header}${item.week}${item.during}${item.period}`;
      if (
        _selectShowText == _itemShowText &&
        ((this.selectObj.levels &&
          this.selectObj.levels == item.classlevel &&
          !this.selectObj.areas &&
          !this.selectObj.teachers) ||
          (this.selectObj.areas &&
            this.selectObj.areas == item.schoolarea &&
            !this.selectObj.levels &&
            !this.selectObj.teachers) ||
          (this.selectObj.teachers &&
            this.selectObj.teachers == item.teachername &&
            !this.selectObj.levels &&
            !this.selectObj.areas) ||
          (this.selectObj.levels &&
            this.selectObj.levels == item.classlevel &&
            this.selectObj.areas &&
            this.selectObj.areas == item.schoolarea &&
            !this.selectObj.teachers) ||
          (this.selectObj.levels &&
            this.selectObj.levels == item.classlevel &&
            this.selectObj.teachers &&
            this.selectObj.teachers == item.teachername &&
            !this.selectObj.areas) ||
          (this.selectObj.teachers &&
            this.selectObj.teachers == item.teachername &&
            this.selectObj.areas &&
            this.selectObj.areas == item.schoolarea &&
            !this.selectObj.levels) ||
          (this.selectObj.teachers &&
            this.selectObj.teachers == item.teachername &&
            this.selectObj.areas &&
            this.selectObj.areas == item.schoolarea &&
            this.selectObj.levels &&
            this.selectObj.levels == item.classlevel))
      ) {
        _tempArr.push(item);
      }
    });
    if (_tempArr.length == 1) {
      this.selectTableItem = { ..._tempArr[0], showText: _selectShowText };
    }
  }
  /**
   * 点击表格元素，设置levels、areas、teachers
   */
  private setSelectRestnum(item: any): void {
    console.log(item, '返回item')
    this.unSelectFlagObj.restnum = false
    // if ( item.status == 3 || item.status == 4 || (this.selectObj.restnum != "" && this.selectObj.restnum != item.showText) || item.full || !item.period) {
    if ( item.status == 3 || (this.selectObj.restnum != "" && this.selectObj.restnum != item.showText) || !item.period) {
      console.log('')
    } else {
      if (this.selectObj.restnum == item.showText) {
        this.selectObj.restnum = "";
        this.selectTableItem = {};
        this.resetStatus("levels", 5, 1);
        this.resetStatus("areas", 5, 1);
        this.resetStatus("teachers", 5, 1);
      } else {
        if (item.mineLevels) {
          this.setStatusToFive("levels", item.mineLevels);
        }
        if (item.mineAreas) {
          this.setStatusToFive("areas", item.mineAreas);
        }
        if (item.mineTeachers) {
          this.setStatusToFive("teachers", item.mineTeachers);
        }
        this.selectObj.restnum = item.showText;

        this.selectTableItem = { ...item };
      }
      if (
        !this.selectObj.levels ||
        !this.selectObj.areas ||
        !this.selectObj.teachers
      ) {
        this.setSubArrBySelect();
      }
      this.commonselectLat(this.selectObj);
      this.curSetDelSelectAttr();
    }
  }
  /**
   * 删除全部已选条件
  */
  public delAll(): void {
    console.log(this.selectObj, '删除全部')
  }

  // 通过明确的表格元素设置
  private setByOnlyTableItem(attr?: string): void {
    let _tempObj: any = {
        levels: "classlevel",
        areas: "schoolarea",
        teachers: "teachername",
        restnum: "",
      },
      _this: any = this;
    Object.keys(this.selectObj).forEach((key: string, idx: number): void => {
      if (!this.selectObj[key] && _tempObj[key]) {
        let _idx: number = this.getIndex(
          this.selectTableItem[_tempObj[key]],
          _this[key],
          "text"
        );
        _this[key].forEach((item: any, index: number): void => {
          if (_idx != index) {
            _this.$set(_this[key][index], "status", 3);
          }
        });
      }
    });
  }

  private curSetDelSelectAttr(): void {
    if (this.delSelectAttr) {
      this.setDelSelectAttr(-1);
    }
  }

  // 鼠标移入显示地图
  private mouseoverEvent(item: any): void {
    // console.log(item)
    this.markerList.forEach((itm: any, index: number): void => {
      let _temp: any = {};
      if (itm.text == item.text) {
        _temp = { ...itm };
      }
      if (_temp.lat && _temp.lng) {
        this.mouseoverFlag = item.text;
        this.initMap(_temp);
      }
    });
  }
  // 鼠标移入隐藏地图
  private mouseleaveEvent(): void {
    this.mouseoverFlag = "";
  }
  // 初始化地图信息，添加标记点
  private initMap(item: any): void {
    let _this: any = this,
      _win: any = window,
      _BMap: any = _win.BMap;

    setTimeout(() => {
      _this.map = new _BMap.Map("allmap");
      let navigationControl: any = new _BMap.NavigationControl({
        // anchor: _win.BMAP_ANCHOR_TOP_LEFT,
        type: _win.BMAP_NAVIGATION_CONTROL_LARGE, // LARGE类型
        enableGeolocation: false, // 启用显示定位
      });
      // _this.map.addControl(navigationControl)
      _this.map.setMapStyle({
        style: "normal",
      });
      let geolocationControl = new _BMap.GeolocationControl();
      _this.map.centerAndZoom(new _BMap.Point(item.lng, item.lat), 20);

      let content: string = `<ul class="flex ai-c jc-s mine-map-cont">
                                            <li class="text">${item.area}：${item.address}</li>
                                            <li class="logo-wrapper"><img src="${logo}" /></li>
                                        </ul>`,
        title: string = item.title,
        poii: any = new _BMap.Point(item.lng, item.lat),
        marker: any = new _BMap.Marker(poii);
      marker.setTitle(item.area);
      _this.map.addOverlay(marker);
    }, 100);
  }
}
